@import "../node_modules/bootstrap/dist/css/bootstrap.css";

body {
    background: transparent;
    /*background-image: url("images/background.jpeg");
    color: white;
    font-weight: bold;*/
}

.row {
    padding-top: 5px;
    padding-bottom: 5px;
}
